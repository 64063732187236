import { ComponentType, Suspense, lazy } from "react";
import { useHydrated } from "remix-utils/use-hydrated";
import { ClientOnly } from "remix-utils/client-only";
import classNames from "classnames";

export function DynamicImport({
  resolver,
  fallback,
  props
}: {
  resolver: Promise<{ default: ComponentType<any> }>;
  fallback?: React.ReactNode | string | null;
  props?: any;
}) {
  const isHydrated = useHydrated();

  const Module = isHydrated && lazy(() => resolver.then(module => module));

  return (
    <ClientOnly>
      {() => (
        <Suspense fallback={fallback || <DefaultSuspenseLoader />}>
          <Module {...props} />
        </Suspense>
      )}
    </ClientOnly>
  );
}

export function DefaultSuspenseLoader({
  className
}: {
  className?: string | undefined;
}) {
  return (
    <div
      className={classNames("flex w-full h-full", className)}
      aria-label="Loading"
    >
      <span className="sr-only">Loading</span>
    </div>
  );
}

