import { Fragment, useState } from "react";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useAppStore } from "~/store";
import { isSSR } from "~/utils/ssr";
import { DynamicImport } from "./DynamicImport";

export function SearchBarInitiator({
  minimized = false
}: {
  minimized?: boolean;
}) {
  const setModal = useAppStore(store => store.modals.setModal);

  const isMac = useState(
    !isSSR() && navigator?.userAgent.toLowerCase().indexOf("mac") >= 0
  );

  if (minimized) {
    return (
      <button
        type="button"
        className="relative flex items-center justify-center w-6 h-6"
        aria-label="Search"
        title="Search"
        onClick={() => setModal("search", true)}
      >
        <FontAwesomeIcon
          icon={faSearch}
          className="text-acc"
          style={{ width: 14, height: 14 }}
          fixedWidth
        />
        <span className="sr-only">Search</span>
      </button>
    );
  }

  return (
    <Fragment>
      <div
        className="relative select-none cursor-pointer"
        onClick={() => setModal("search", true)}
      >
        <div className="outline-none absolute inset-y-0 left-0 flex items-center pl-4 cursor-pointer">
          <svg
            aria-hidden="true"
            className="w-5 h-5 text-gray-500 dark:text-zinc-500"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
            ></path>
          </svg>
        </div>
        <div
          className="flex flex-row justify-between items-center w-full px-2 py-2.5 
              text-sm border border-pri dark:border-pri-d rounded-full bg-gray-50
              focus:border-acc dark:bg-inherit
              dark:placeholder-gray-400 dark:hover:shadow-[0_0_12px_3px_rgb(255_255_255_/_17%)] hover:drop-shadow-md
              dark:focus:ring-acc dark:focus:border-acc outline-none text-gray-500 dark:text-zinc-500 transition-colors duration-150"
        >
          <span className="pl-9">Search anything</span>
          <div className="justify-between w-fit text-xxs flex flex-row gap-x-1 pr-2">
            <span className="monospace font-semibold rounded-md border border-pri dark:border-pri-d bg-pri-d/[.075] dark:bg-pri/[.075] px-1.5 text-pri dark:text-pri-d">
              {isMac ? "⌘" : "CTRL"}
            </span>
            <span className="font-bold rounded-sm text-pri dark:text-pri-d">
              +
            </span>
            <span className="monospace font-semibold rounded-md px-1.5 border border-pri dark:border-pri-d bg-pri-d/[.075] dark:bg-pri/[.075] text-pri dark:text-pri-d">
              K
            </span>
          </div>
        </div>
      </div>

      <DynamicImport
        resolver={import("~/components/SearchBar/Search")}
        fallback={<></>}
      />
    </Fragment>
  );
}
